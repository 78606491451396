// prettier-ignore

const RoutesAdmin = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "adminHome" */ '../views/Home.vue'),
    meta: {
      allow: true,
    },
  },
  {
    path: '/newmarketing',
    name: 'NewMarketing',
    component: () => import(/* webpackChunkName: "newmarketing" */ '../views/NewMarketing.vue'),
  },

  {
    path: '/newmarketing/anal/:officeno',
    name: 'NewMarketingAnal',
    component: () => import(/* webpackChunkName: "newmarketingAnal" */ '../views/NewMarketingAnal.vue'),
  },
  {
    path: '/marketing/con',
    name: 'MarketingCon',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingCon.vue'),
  },
  {
    path: '/marketing/ser',
    name: 'MarketingSer',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingSer.vue'),
  },
  {
    path: '/marketing/pur',
    name: 'MarketingPur',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingPur.vue'),
  },
  {
    path: '/newmarketing/con',
    name: 'NewMarketingCon',
    component: () => import(/* webpackChunkName: "newmarketingcon" */ '../views/NewMarketingCon.vue'),
  },
  {
    path: '/marketing/con/:id',
    name: 'MarketingConDetail',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingConDetail.vue'),
  },
  {
    path: '/marketing/pur/:id',
    name: 'MarketingPurDetail',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingPurDetail.vue'),
  },
  {
    path: '/marketing/ser/:id',
    name: 'MarketingSerDetail',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingSerDetail.vue'),
  },
  {
    path: '/counsel',
    name: 'counsel',
    component: () => import(/* webpackChunkName: "counsel1" */ '../views/Counsel.vue'),
    children: [
      {
        name: 'counsel-folder',
        path: '/counsel/:num/:name',
        props: true,
        component: () => import(/* webpackChunkName: "counsel-folder" */ '../views/Counsel.vue'),
      },
    ],
  },
  {
    path: '/bidres',
    name: 'bidres',
    component: () => import(/* webpackChunkName: "bidres" */ '../views/Bidres.vue'),
  },
  {
    path: '/bidres/congsuc/:num',
    name: 'congsuc',
    component: () => import(/* webpackChunkName: "congsuc" */ '../views/CongSuc.vue'),
  },
  {
    path: '/commission/:analdata/:bidid',
    name: 'commission',
    component: () => import(/* webpackChunkName: "commission" */ '../views/Commission.vue'),
  },
  {
    path: '/content',
    name: 'content',
    component: () => import(/* webpackChunkName: "Content" */ '../views/Content.vue'),
    children: [
      {
        name: 'content-bid',
        path: 'bid/:bidid',
        props: true,
        component: () => import(/* webpackChunkName: "contentbid" */ '../views/ContentBid.vue'),
      },
      {
        name: 'content-suc',
        path: 'suc/:bidid',
        props: true,
        component: () => import(/* webpackChunkName: "contentsuc" */ '../views/ContentSuc.vue'),
      },
    ],
  },
  {
    path: '/user/update/:mem_num',
    name: 'userupdate',
    props: true,
    component: () => import(/* webpackChunkName: "userupdate" */ '../views/UserUpdate.vue'),
  },
  {
    path: '/user/',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
  },
  {
    path: '/userdormant/',
    name: 'userdormant',
    component: () => import(/* webpackChunkName: "userdormant" */ '../views/UserDormant.vue'),
  },
  {
    path: '/officeanal/:officeno/:sess_team_id/:sess_staff_num',
    name: 'officeanal',
    component: () => import('../views/OfficeAnal.vue'),
  },
  {
    path: '/ubid/:mem_num',
    name: 'ubid',
    component: () => import('../views/Ubid.vue'),
  },
  {
    path: '/mng/',
    name: 'mng',
    props: true,
    component: () => import(/* webpackChunkName: "mng" */ '../views/Mng.vue'),
    children: [
      {
        name: 'mng-bidid',
        path: '/mng/:bidid/:bid',
        props: true,
        component: () => import(/* webpackChunkName: "mng-bidid" */ '../views/BidBox.vue'),
      },
    ],
  },
  {
    name: 'mng-search',
    path: '/mng/:mem_num/:startdate/:enddate/:stat',
    props: true,
    component: () => import(/* webpackChunkName: "mng-bidid" */ '../views/Mng.vue'),
  },
  {
    name: 'popover',
    path: '/popover/:mem_num',
    props: true,
    component: () => import(/* webpackChunkName: "noti" */ '../views/Popover.vue'),
  },
  {
    path: '/bidsearch',
    name: 'bidsearch',
    component: () => import(/* webpackChunkName: "bidsearch" */ '../views/BidSearch.vue'),
  },
  {
    path: '/usernotice',
    name: 'UserNotice',
    component: () => import(/* webpackChunkName: "usernotice" */ '../views/UserNotice.vue'),
  },
  {
    path: '/usernotice/create',
    name: 'UserNoticeCreate',
    component: () => import(/* webpackChunkName: "usernotice" */ '../views/UserNoticeCreate.vue'),
  },
  {
    path: '/usernotice/update/:id',
    name: 'UserNoticeUpdate',
    component: () => import(/* webpackChunkName: "usernotice" */ '../views/UserNoticeCreate.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login1" */ '../views/Login.vue'),
    meta: {
      unauthorized: true,
    },
  },
  {
    path: '/login2/:userid',
    name: 'login2',
    component: () => import(/* webpackChunkName: "login2" */ '../views/Login2.vue'),
    meta: {
      unauthorized: true,
    },
  },
  {
    path: '/stat/tuchal',
    name: 'tuchal',
    component: () => import(/* webpackChunkName: "sales" */ '../views/Tuchal.vue'),
  },
  {
    path: '/stat/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '../views/Sales.vue'),
  },
  {
    path: '/stat/mem',
    name: 'memstat',
    component: () => import(/* webpackChunkName: "memstat" */ '../views/MemStat.vue'),
  },
  {
    path: '/stat/payed',
    name: 'payed',
    component: () => import(/* webpackChunkName: "payed" */ '../views/Payed.vue'),
  },
  {
    path: '/stat/bidresult',
    name: 'bidresult',
    component: () => import(/* webpackChunkName: "bidresult" */ '../views/BidResult.vue'),
    children: [
      {
        name: 'bidresult-search',
        path: '/stat/bidresult/:startdate?/:enddate?/:column?/:search?/:tuchal?',
        props: true,
        component: () => import(/* webpackChunkName: "mng-bidid" */ '../views/BidResult.vue'),
      },
    ],
  },
  {
    path: '/stat/smslog',
    name: 'smslog',
    component: () => import(/* webpackChunkName: "smslog" */ '../views/Smslog.vue'),
  },
  {
    path: '/counselreq',
    name: 'counselreq',
    component: () => import(/* webpackChunkName: "counselreq" */ '../views/Counselreq.vue'),
  },
  {
    path: '/adminnotice',
    name: 'adminnotice',
    component: () => import(/* webpackChunkName: "adminnotice" */ '../views/AdminNotice.vue'),
  },
  {
    path: '/adminnotice/create',
    name: 'adminnotice-create',
    component: () => import(/* webpackChunkName: "counselreq" */ '../views/AdminNoticeCreate.vue'),
  },
  {
    path: '/adminnotice/update/:id',
    name: 'adminnotice-update',
    component: () => import(/* webpackChunkName: "counselreq" */ '../views/AdminNoticeCreate.vue'),
  },
  {
    path: '/admin/list',
    name: 'adminlist',
    component: () => import(/* webpackChunkName: "adminlist" */ '../views/AdminList.vue'),
  },
  {
    path: '/newupdatenotice',
    name: 'NewUpdateNotice',
    component: () => import(/* webpackChunkName: "newupdatenotice" */ '../views/NewUpdateNotice.vue'),
  },
  {
    path: '/newupdate-notice-create',
    name: 'newupdatenoticecreate',
    component: () => import(/* webpackChunkName: "newupdatenotice" */ '../views/NewUpdateNoticeCreate.vue'),
  },
  {
    path: '/newupdatenotice/update/:id',
    name: 'newupdatenotice-update',
    component: () => import(/* webpackChunkName: "newupdatenotice" */ '../views/NewUpdateNoticeCreate.vue'),
  },
  {
    path: '/admin/log',
    name: 'adminlog',
    component: () => import(/* webpackChunkName: "adminlog" */ '../views/AdminLog.vue'),
  },
  {
    path: '/Mngoffice/:bidid',
    name: 'Mngoffice',
    component: () => import(/* webpackChunkName: "mngoffice" */ '../views/Mngoffice.vue'),
  },
  /*{
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Admin,
    children: [
    ]
  },*/
  {
    path: '/admin/userlog',
    name: 'userlog',
    component: () => import(/* webpackChunkName: "userlog" */ '../views/UserLog.vue'),
  },
  {
    path: '/config',
    name: 'config',
    component: () => import(/* webpackChunkName: "config" */ '../views/Config.vue'),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: import(/* webpackChunkName: "About" */ '../views/About.vue'),
    // component: () =>
    // import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: '/noti',
    name: 'noti',
    component: () => import(/* webpackChunkName: "noti" */ '../views/Noti.vue'),
  },
  {
    path: '/auth/assignment',
    name: 'assignment',
    component: () => import(/* webpackChunkName: "assignment" */ '../views/Assignment.vue'),
  },
  {
    path: '/auth/assignment/view/:id',
    name: 'assignment-view',
    component: () => import(/* webpackChunkName: "assignment-view" */ '../views/AssignmentView.vue'),
  },
  {
    path: '/auth/role',
    name: 'role',
    component: () => import(/* webpackChunkName: "role" */ '../views/Role.vue'),
  },
  {
    path: '/auth/role/create',
    name: 'role-create',
    component: () => import(/* webpackChunkName: "role-create" */ '../views/RoleCreate.vue'),
  },
  {
    path: '/auth/role/update/:name',
    name: 'role-update',
    component: () => import(/* webpackChunkName: "role-update" */ '../views/RoleCreate.vue'),
  },
  {
    path: '/auth/role/view/:name',
    name: 'role-view',
    component: () => import(/* webpackChunkName: "role-view" */ '../views/RoleView.vue'),
  },
  {
    path: '/auth/task',
    name: 'task',
    component: () => import(/* webpackChunkName: "task" */ '../views/Task.vue'),
  },
  {
    path: '/auth/task/create',
    name: 'task-create',
    component: () => import(/* webpackChunkName: "task-create" */ '../views/TaskCreate.vue'),
  },
  {
    path: '/auth/task/update/:name',
    name: 'task-update',
    component: () => import(/* webpackChunkName: "task-update" */ '../views/TaskCreate.vue'),
  },
  {
    path: '/auth/task/view/:name',
    name: 'task-view',
    component: () => import(/* webpackChunkName: "task-view" */ '../views/TaskView.vue'),
  },
  {
    path: '/auth/operation',
    name: 'operation',
    component: () => import(/* webpackChunkName: "operation" */ '../views/Operation.vue'),
  },
  {
    path: '/auth/operation/create',
    name: 'operation-create',
    component: () => import(/* webpackChunkName: "operation-create" */ '../views/OperationCreate.vue'),
  },
  {
    path: '/auth/operation/update/:name',
    name: 'operation-update',
    component: () => import(/* webpackChunkName: "operation-update" */ '../views/OperationCreate.vue'),
  },
  {
    path: '/auth/operation/view/:name',
    name: 'operation-view',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/OperationView.vue'),
  },
  {
    path: '/office/:officenm?/:pre_name?',
    name: 'office',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/Office.vue'),
  },
  {
    path: '/office/:officeno?/:officenm?/:pre_name?',
    name: 'office',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/Office.vue'),
  },

  {
    path: '/siteconfigset',
    name: 'siteconfigset',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/SiteConfigSet.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
  {
    path: '/sitemanage',
    name: 'sitemanage',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/SiteManage.vue'),
  },
  {
    path: '/newmarketing/detail/:officeno',
    name: 'NewMarketingDetail',
    component: () => import(/* webpackChunkName: "NewMarketingInfo" */ '../views/NewMarketingInfo.vue'),
  },
  {
    path: '/newmarketing/anal2/:officeno/:org?',
    name: 'AnalysisTuchal',
    component: () => import(/* webpackChunkName: "AnalysisTuchal" */ '../views/AnalysisTuchal.vue'),
  },
  {
    path: '/layerpopup',
    name: 'LayerPopup',
    component: () => import(/* webpackChunkName: "LayerPopup" */ '../views/LayerPopup.vue'),
  },
  {
    path: "/error/:errorCode?",
    name: "ErrorPage",
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/ErrorPage.vue'),
    props: true,
  },
];

// prettier-ignore
const RoutesWWW = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/www/Main.vue'),
    meta: {
      unauthorized: true,
    },
  },
  {
    path: '/login/auto/:token',
    name: 'loginauto',
    props: true,
    component: () => import(/* webpackChunkName: "loginauto" */ '../views/www/LoginAuto.vue'),
    meta: {
      unauthorized: true,
    },
  },
  {
    path: '/content',
    name: 'content',
    component: () => import(/* webpackChunkName: "Content" */ '../views/Content.vue'),
    children: [
      {
        name: 'content-bid',
        path: 'bid/:bidid',
        props: true,
        component: () => import(/* webpackChunkName: "contentbid" */ '../views/ContentBid.vue'),
      },
      {
        name: 'content-suc',
        path: 'suc/:bidid',
        props: true,
        component: () => import(/* webpackChunkName: "contentsuc" */ '../views/ContentSuc.vue'),
      },
    ],
  },
  {
    path: '/office/:officenm?/:pre_name?',
    name: 'office',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/Office.vue'),
  },
  {
    path: '/office/:officeno?/:officenm?/:pre_name?',
    name: 'office',
    component: () => import(/* webpackChunkName: "operation-view" */ '../views/Office.vue'),
  },

  {
    path: '/bidhelper',
    name: 'bidhelper',
    component: () => import('../components/www/Layout.vue'),
    children: [
      {
        name: 'case1',
        path: 'case1/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase1.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case2',
        path: 'case2/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase2.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case3',
        path: 'case3/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase3.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case4',
        path: 'case4/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase4.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case5',
        path: 'case5/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase5.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case6',
        path: 'case6/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase6.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
      {
        name: 'case11',
        path: 'case11/',
        component: () => import(/* webpackChunkName: "bidhelper-case1" */ '../views/www/BidhelperCase11.vue'),
        meta: {
          unauthorized: true,
          layout: 'blank',
        },
      },
    ],
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('../components/www/Layout.vue'),
    children: [
      {
        name: 'bid',
        path: 'bid/',
        props: true,
        component: () => import(/* webpackChunkName: "Bid" */ '../views/www/Bid.vue'),
      },
      {
        name: 'suc',
        path: 'suc/',
        props: true,
        component: () => import(/* webpackChunkName: "Suc" */ '../views/www/Suc.vue'),
      },
      {
        name: 'myBid',
        path: 'mybid/',
        props: true,
        component: () => import(/* webpackChunkName: "Mybid" */ '../views/www/Mybid.vue'),
      },
      {
        name: 'myResult',
        path: 'myresult/',
        props: true,
        component: () => import(/* webpackChunkName: "MyResult" */ '../views/www/MyResult.vue'),
      },
      {
        name: 'clientBidSearch',
        path: 'bidsearch/',
        props: true,
        component: () => import(/* webpackChunkName: "ClientBidSearch" */ '../views/www/ClientBidSearch.vue'),
      },
      {
        name: 'clientNoticeList',
        path: 'noticeList/',
        component: () => import(/* webpackChunkName: "NoticeList" */ '../views/www/NoticeList.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'clientNoticeView',
        path: 'noticeview/',
        component: () => import(/* webpackChunkName: "NoticeView" */ '../views/www/NoticeView.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'service',
        path: 'service/',
        component: () => import(/* webpackChunkName: "Service" */ '../views/www/Service.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'manual',
        path: 'manual/',
        component: () => import(/* webpackChunkName: "Manual" */ '../views/www/Manual.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'interest',
        path: 'interest/',
        component: () => import(/* webpackChunkName: "Interest" */ '../views/www/Interest.vue'),
      },
      {
        name: 'company',
        path: 'company/',
        props: true,
        component: () => import(/* webpackChunkName: "Company" */ '../views/www/Company.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'counsel',
        path: 'counsel/',
        props: true,
        component: () => import(/* webpackChunkName: "Counsel" */ '../views/www/Counsel.vue'),
        meta: {
          unauthorized: true,
          layout: 'popup',
          windowtitle: '상담신청하기',
        },
      },
      {
        name: 'changepass',
        path: 'changepass/',
        props: true,
        component: () => import(/* webpackChunkName: "Changepass" */ '../views/www/ChangePass.vue'),
      },
      {
        name: 'login',
        path: 'login/',
        component: () => import(/* webpackChunkName: "Login" */ '../views/www/Login.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'RegistMember',
        path: 'registmember/',
        component: () => import(/* webpackChunkName: "RegistMember" */ '../views/www/RegistMember.vue'),
        meta: {
          unauthorized: true,
        },
      },
      {
        name: 'JoinTermsPrivacyCollectUse',
        path: 'join_terms_privacy_collect_use/',
        component: () =>
            import(/* webpackChunkName: "JoinTermsPrivacyCollectUse" */ '../views/www/JoinTermsPrivacyCollectUse.vue'),
        meta: {
          unauthorized: true,
          layout: 'popup',
          windowtitle: '개인정보제공내용',
        },
      },
      {
        name: 'JoinTermsService',
        path: 'join_terms_service/',
        component: () => import(/* webpackChunkName: "JoinTermsService" */ '../views/www/JoinTermsService.vue'),
        meta: {
          unauthorized: true,
          layout: 'popup',
          windowtitle: '이용약관',
        },
      },
    ],
  },
  {
    path: "/error/:errorCode?",
    name: "ErrorPage",
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/ErrorPage.vue'),
    props: true,
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
];

export { RoutesAdmin, RoutesWWW };
