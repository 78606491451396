import auth from './auth';
import bidresult from './bidresult';
import marketing from './marketing';
import memcounsel from './memcounsel';
import memstat from './memstat';
import mybid from './mybid';
import powerkey from './powerkey';
import officeanal from './officeanal';
import sales from './sales';
import tuchal from './tuchal';
import admin from './admin';
import adminlog from './adminlog';
import payed from './payed';
import user from './user';
import usernotice from './usernotice';
import daemonlog from './daemon-log';
import smslog from './smslog';
import home from './home';
import counselreq from './counselreq';
import bidsearch from './bidsearch';
import code from './code';
import adminnotice from './adminnotice';
import config from './config';
import noti from './noti';
import assignment from './assignment';
import role from './role';
import roleChild from './role-child';
import task from './task';
import taskChild from './task-child';
import operation from './operation';
import operationChild from './operation-child';
import userlog from './userlog';
import v3bidsuccom from './v3bidsuccom';
import commission from './commission';
import office from './office';
import userContract from './user-contract';
import userAnalconfig from './user-analconfig';
import userResult from './user-result';
import userCommission from './user-commission';
import userCounsel from './user-counsel';
import userUpdate from './user-update';
import counselFolder from './counsel-folder';
import counselFolderData from './counsel-folder-data';
import congsuc from './congsuc';
import MemMarketingFolderData from './mem-marketing-folder-data';
import officeEtc from './office-etc';
import userState from './user-state';
import siteConfigSet from './siteconfigset';
import analysisTuchal from './analysis-tuchal';
import newupdatenotice from './newupdatenotice';
import userFiles from './user-files';

export default {
  ...auth,
  ...bidresult,
  ...marketing,
  ...memcounsel,
  ...mybid,
  ...officeanal,
  ...sales,
  ...powerkey,
  ...memstat,
  ...tuchal,
  ...admin,
  ...adminlog,
  ...payed,
  ...user,
  ...usernotice,
  ...daemonlog,
  ...smslog,
  ...home,
  ...counselreq,
  ...bidsearch,
  ...code,
  ...adminnotice,
  ...config,
  ...noti,
  ...assignment,
  ...role,
  ...roleChild,
  ...task,
  ...taskChild,
  ...operation,
  ...operationChild,
  ...userlog,
  ...v3bidsuccom,
  ...commission,
  ...office,
  ...userContract,
  ...userAnalconfig,
  ...userResult,
  ...userCommission,
  ...userCounsel,
  ...userUpdate,
  ...counselFolder,
  ...counselFolderData,
  ...congsuc,
  ...MemMarketingFolderData,
  ...officeEtc,
  ...userState,
  ...siteConfigSet,
  ...analysisTuchal,
  ...newupdatenotice,
  ...userFiles,
};
