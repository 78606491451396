export default {
  mybid_form: (state) => state.mybid_form,
  bidsearch_form: (state) => state.bidsearch_form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  succCnt: (state) => state.succCnt,

  //개별선택 엑셀저장(selected)
  selected: (state) => state.selected,
};
