import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    biddate_term: '0',
    bidtype: '',
    itemcode1: '',
    itemcode2: '',
    itemcode3: '',
    location: '',
    addr: '',
    officeno: '',
    officename: '',
    tel: '',
    prename: '',
    fax: '',
    sort: 'id',
    desc: 'desc',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 40,
    totalElements: 0,
    pages: 1,
  },
  check_com: [],
  items_loading: false,
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  check_com: (state) => state.check_com,
  items_loading: (state) => state.items_loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.total;
  },
  FETCH_UPDATE_SUCCESS: (state, response) => {
    const data = response['data'];
    state.items = state.items.map((item) => {
      if (data[item.officeno]) {
        return {
          ...item,
          ...data[item.officeno],
        };
      }
      return item;
    });
  },
  setItemsLoading: (state, payload) => {
    state.items_loading = payload;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      form.shopkey = window.siteconfig.name;
      const { data } = await api.fetchNewMarketingCon({
        ...form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
      state.items_loading = true;
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    } finally {
      state.loading = false;
    }
  },
  fetch_update: async ({ commit }) => {
    try {
      const { data } = await api.fetchNewMarketingConUpdate({
        ...state.items,
      });
      commit('FETCH_UPDATE_SUCCESS', data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  setViewCom: ({ state }, session_com) => {
    state.check_com = session_com;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
