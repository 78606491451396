<template>
  <ErrorPage :errorCode="errorStore.getters.code" v-if="errorStore.getters.error" />
  <router-view v-else></router-view>
</template>

<script>
import ErrorPage from './views/ErrorPage.vue';
import { errorStore } from './store/index.js';

// errorStore.dispatch('setError', { code: 404, message: 'Not Found' });
// errorStore.dispatch('clearError');
// errorStore.dispatch('setError', { code: 500, message: 'test' });

export default {
  name: 'App',
  data() {
    return {};
  },
  computed: {
    errorStore() {
      return errorStore;
    },
  },
  components: {
    ErrorPage,
  },
};
</script>
