import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import VueCookies from 'vue-cookies';
import { userlogout } from '../auth.js';
import router from '../router/index.www.js';
import { store, errorStore } from '../store/index';
import * as actionTypes from '@/store/action-types';
import refreshTokenProcess from './refresh-token-process.js';

Vue.use(VueAxios, axios);

axios.defaults.headers.common = {
  // Authorization: `Bearer ${VueCookies.get('token')}`,
  Authorization: `Bearer ${store.getters.accessToken}`,
  Shopkey: window.siteconfig.name,
};
axios.defaults.baseURL = process.env.VUE_APP_API;

// 사용자 로그아웃
const logoutUser = async () => {
  await userlogout();
  await store.dispatch(actionTypes.LOGOUT, { ltype: 'member' });
  await router.push({
    name: 'home',
  });
};

// 토큰이 없는 경우 로그아웃 처리, 토큰이 있는데 에러가 발생한 경우 에러 처리
const checkTokenWithLogout = async (reqToken, { errCode, errMessage }) => {
  if (
    !reqToken ||
    reqToken === 'Bearer null' ||
    reqToken === 'Bearer undefined' ||
    reqToken === 'Bearer' ||
    reqToken === ''
  ) {
    console.log('access token is null, logout!');
    await logoutUser();
  } else {
    errorStore.dispatch('setError', { code: errCode, message: errMessage });
  }
};

// 리퀘스트 인터셉터
axios.interceptors.request.use(
  async (config) => {
    config = refreshTokenProcess(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 리스폰스 인터셉터
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.error('[axios error]', error);
    if (error.response) {
      const statusCode = error.response.status;
      const msg = error.response.data?.msg;
      if (statusCode == 401) {
        console.log('401 error -->', error.response);
        if (msg) {
          alert(msg);
        } else {
          alert('[401] 세션이 만료되었습니다. 다시 로그인해주세요.');
        }
        await logoutUser();
      } else if (statusCode == 403) {
        errorStore.dispatch('setError', { code: 403, message: 'Forbidden' });
      } else if (statusCode == 404) {
        errorStore.dispatch('setError', { code: 404, message: 'Not Found' });
      } else if (statusCode == 422) {
        console.log('422 error -->', error.response);
        const reqToken = error.response.config.headers.Authorization?.trim();
        await checkTokenWithLogout(reqToken, { errCode: 422, errMessage: 'Unprocessable Entity' });
      } else if (statusCode == 500) {
        console.log('500 error -->', error.response);
        const reqToken = error.response.config.headers.Authorization?.trim();
        await checkTokenWithLogout(reqToken, { errCode: 500, errMessage: 'Internal Server Error' });
      } else {
        console.log(`[${statusCode}] error -->`, error.response);
        errorStore.dispatch('setError', { code: statusCode, message: 'An error occurred' });
      }
    } else {
      // 응답이 없는 경우 (네트워크 에러 등)
      console.log('[axios error] 응답이 없음 (네트워크 에러 등)', error);
      errorStore.dispatch('setError', { code: 500, message: 'Internal Server Error' });
    }
    return Promise.reject(error);
  }
);

export default new VueAxios({});
