// newupdatenotice (최신업데이트 안내)
import Vue from 'vue';

export default {
  async createNewNotice(params) {
    return Vue.axios.post(`/api/newupdatenotice/create`, params);
  },
  async deleteNewNotice(params) {
    return Vue.axios.delete(`/api/newupdatenotice/delete`, {
      params: params,
    });
  },
  async fetchNewNotice(params) {
    return Vue.axios.get(`/api/newupdatenotice/list`, {
      params: params,
    });
  },
  async getNewNotice(params) {
    return Vue.axios.get(`/api/newupdatenotice/get`, {
      params: params,
    });
  },
  async updateNewNotice(params) {
    return Vue.axios.put(`/api/newupdatenotice/update`, params);
  },
};
