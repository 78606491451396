import Vue from 'vue';
import qs from 'qs';

export default {
  async getBizBasicV2(params) {
    return Vue.axios.get(`/api/table/bizbasic/data`, {
      params: params,
    });
  },

  async fetchAnalysisTuchal(params) {
    const path = '/api/esbid/analysis_tuchal_db';
    const method = 'GET';

    params = {
      path: path,
      method: method,
      ...params,
    };
    console.log(params);
    return await Vue.axios.get(`/api/analysis_gate/infose_api_proxy/`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    // const queryString = qs.stringify(params, { arrayFormat: 'repeat' });
    // const url = `/api/analysis_gate/infose_api_proxy/?${queryString}`;
    // console.log(url);
    // try {
    //   const response = await Vue.axios.get(url);
    //   console.log(response);
    //   return response;
    // } catch (error) {
    //   console.error(error);
    //   return error;
    // }
  },
};
