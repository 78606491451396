<template>
  <div class="text-center error-page">
    <img src="https://storage.googleapis.com/infose-bms-public/common/images/errorPage.png" />
    <p class="error-code">
      {{ errorCode }}
    </p>
    <p v-html="errorMessage" class="msg"></p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data: () => ({
    dataProps: {},
  }),
  props: {
    errorCode: {
      type: String,
      default: 'unknown',
    },
  },
  computed: {
    errorMessage() {
      switch (this.errorCode) {
        case '404':
          return (
            '페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.<br>\n' +
            '입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.<br>\n' +
            '문제가 지속되는 경우 고객센터로 문의주시면, 친절히 안내해드리겠습니다.'
          );
        case '400':
          return '잘못된 요청입니다.<br>\n' + '문제가 지속되는 경우 고객센터로 문의주시면, 친절히 안내해드리겠습니다.';
        case '403':
          return (
            '서비스 이용 권한이 없습니다.<br>\n' +
            '문제가 지속되는 경우 고객센터로 문의주시면, 친절히 안내해드리겠습니다.'
          );
        case '500':
          return (
            '시스템 오류로 인하여 서비스를 정상적으로 이용하실 수 없습니다.<br>\n' +
            '잠시 후 다시 접속해 주시기 바라며, 이용에 불편을 드린점 사과의 말씀 드립니다.<br>\n' +
            '문제가 지속되는 경우 고객센터로 문의주시면, 친절히 안내해드리겠습니다.'
          );
        default:
          return '오류가 발생했습니다.<br>잠시 후 다시 시도해 주세요.';
      }
    },
  },
};
</script>

<style scoped>
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height:100vh; */
  margin-top: 80px;
}

.error-code {
  font-size: 70px;
  color: #666;
  margin-top: 26px;
}

.msg {
  font-size: 24px;
  color: #666;
  margin-top: 26px;
}

.text-center {
  text-align: center !important;
}
</style>
