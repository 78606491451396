import Vue from 'vue';

export default {
  async fetchFiles(params) {
    return Vue.axios.get(`/api/user/files-list`, {
      params: params,
    });
  },
  async createFiles(params) {
    return Vue.axios.post(`/api/user/files-create`, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  async deleteFiles(params) {
    return Vue.axios.delete(`/api/user/files-delete`, params);
  },
};
