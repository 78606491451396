// import axios from "axios";
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { store } from './store/index';
import * as mutationTypes from './store/mutation-types';

export async function userlogout() {
  try {
    // Vue.axios.defaults.headers.common['Authorization'] = undefined;
    Vue.axios.defaults.headers.common = {};
    VueCookies.remove('userid');
    VueCookies.remove('token');
    VueCookies.remove('refresh_token');
    VueCookies.remove('name');
    VueCookies.remove('userinfo');
    VueCookies.remove('usernum');
    VueCookies.remove('refreshed');
  } catch (err) {
    return err;
  }
}

export async function userlogin(userid, password) {
  try {
    let access_token = VueCookies.get('token');
    Vue.axios.defaults.headers.common = {
      Authorization: `Bearer ${access_token}`,
      Shopkey: window.siteconfig.name,
    };

    const token = await Vue.axios.post('/api/auth/login', {
      userid,
      password,
    });
    VueCookies.set('userid', userid, '1d');
    VueCookies.set('token', token.data.token, '60min');
    VueCookies.set('refresh_token', token.data.refresh_token, '30d');
    Vue.axios.defaults.headers['refresh_token'] = VueCookies.get('refresh_token');
    return token;
  } catch (err) {
    return err;
  }
}

export async function refreshToken() {
  // console.log('토큰 재발급 시도...');
  try {
    const refresh_token = VueCookies.get('refresh_token');
    const _axios = Vue.axios.create({
      baseURL: process.env.VUE_APP_API + '/api/auth/refresh',
    });
    _axios.defaults.headers.common = {
      Authorization: `Bearer ${refresh_token}`,
      Shopkey: window.siteconfig.name,
    };
    const response = await _axios.post();
    const refreshed = parseInt(new Date().valueOf() / 1000);

    VueCookies.set('token', response.data.token, '60min');
    VueCookies.set('userid', VueCookies.get('userid'), '1d');
    VueCookies.set('usernum', VueCookies.get('usernum'), '1d');
    VueCookies.set('name', VueCookies.get('name'), '30d');
    VueCookies.set('userinfo', VueCookies.get('userinfo'), '30d');
    VueCookies.set('refreshed', refreshed, '60min');

    // store 에 토큰과 갱신시간 저장
    store.commit(mutationTypes.UPDATE_ACCESS_TOKEN, {
      token: response.data.token,
      refreshed: refreshed,
    });

    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

    return response;
  } catch (err) {
    await userlogout();
    return err;
  }
}

export async function getData() {
  let access_token = VueCookies.get('token');
  Vue.axios.defaults.headers.common = {
    Authorization: `Bearer ${access_token}`,
    Shopkey: window.siteconfig.name,
  };

  await Vue.axios
    .get('/api/auth/data')
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error('BMS SEARCH ERROR : ' + error);
    });
}

export async function refreshAccessTokenAuto(renewDuration = 60 * 20) {
  // 기본값: 20분마다 액세스 토큰 갱신
  // const refToken = VueCookies.get('refresh_token');
  // const accToken = VueCookies.get('token');
  // const refreshed = VueCookies.get('refreshed') ? parseInt(VueCookies.get('refreshed')) : 0;

  const refToken = store.getters.refreshToken;
  const accToken = store.getters.accessToken;
  const refreshed = store.getters.refreshed ? parseInt(store.getters.refreshed) : 0;

  if (!refToken) {
    if (!accToken) {
      // refresh_token, token 둘다 없을 경우 로그아웃
      await userlogout();
    }
    return;
  }

  if (!accToken) {
    // token 만 없을 경우 refresh_token 으로 토큰 재발급
    await refreshToken();
    return;
  }

  const now = parseInt(new Date().valueOf() / 1000);
  const renew = now - refreshed;
  if (renew > renewDuration) {
    // 20분 이상 경과하면 토큰 재발급
    await refreshToken();
  }
}
