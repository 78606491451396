import Vue from 'vue';

export default {
  async fetchAnalconfig(params) {
    return Vue.axios.get(`/api/user/analconfig-list`, {
      params: params,
    });
  },
  async createAnalconfig(params) {
    return Vue.axios.put(`/api/user/analconfig-save`, params);
  },
  async updateAllAnalconfig(params) {
    return Vue.axios.put(`/api/user/analconfig-allupdate`, params);
  },
  async deleteAnalconfig(params) {
    return Vue.axios.delete(`/api/user/analconfig-delete`, params);
  },
  async analconfigLog(params) {
    return Vue.axios.get(`/api/user/analconfig-log`, {
      params: params,
    });
  },
};
