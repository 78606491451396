import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    date_type: 'sdt',
    startdate: '',
    enddate: '',
    column: 'officename',
    word: '',
    payed_stat: '',
    manager: '',
    counseler: '',
    page: 1,
    itemsPerPage: 30,
  },
  items: [],
  managers: {},
  counselers: {},
  loading: false,
  total: {
    count: 0,
    smoney: 0,
    commission: 0,
    commission_payed: 0,
  },
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  managers: (state) => state.managers,
  counselers: (state) => state.counselers,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.managers = {};
    state.counselers = {};
    state.loading = true;
    state.total = {
      count: 0,
      smoney: 0,
      commission: 0,
      commission_payed: 0,
    };
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.managers = response.managers;
    state.counselers = response.counselers;
    state.loading = false;
    state.total = response.total;
    state.form.counseler = response.counseler_num;
    state.form.manager = response.mng_num;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.managers = {};
    state.counselers = {};
    state.loading = false;
    state.total = {
      count: 0,
      smoney: 0,
      commission: 0,
      commission_payed: 0,
    };
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchPayed({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
