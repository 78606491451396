import Vue from 'vue';
import App from './App.vue';
import router from './router/index.www.js';
import { store } from './store';
import VueAxios from './plugins/vue-axios.www';
import VueClipboard from 'vue-clipboard2';
import vuetify from './plugins/vuetify';
import './plugins/quill';
import './plugins/sweet-alert';
import './filters';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import HighchartsMore from 'highcharts/highcharts-more';
// import MetaNaver from '@/components/www/MetaNaver.vue';
// import MetaGoogle from '@/components/www/MetaGoogle.vue';

stockInit(Highcharts);
HighchartsMore(Highcharts);
Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
});
Vue.use(HighchartsVue);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  VueAxios,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// new Vue({
//   render: (h) => h(MetaNaver),
// }).$mount('[name="naver-site-verification"]');

// new Vue({
//   render: (h) => h(MetaGoogle),
// }).$mount('[name="google-site-verification"]');
