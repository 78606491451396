import * as mutationTypes from './mutation-types';

export default {
  [mutationTypes.ADD_CUSTOM]: (state) => {
    state.loading = true;
  },
  [mutationTypes.ADD_CUSTOM_SUCCESS]: (state, data) => {
    state.form = {};
    state.succCnt = data.succCnt;
    state.loading = false;
  },
  [mutationTypes.ADD_CUSTOM_FAILURE]: (state) => {
    state.form = {};
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: (state) => {
    state.form = {};
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: (state) => {
    state.form = {};
    state.loading = false;
  },
  [mutationTypes.FETCH]: (state, page) => {
    state.items = [];
    state.loading = true;
    state.pagination.page = page;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  // [mutationTypes.FETCH_MYRESULT]: (state, page) => {
  //   state.items = [];
  //   state.loading = true;
  //   state.pagination.page = page;
  //   state.pagination.totalElements = 0;
  //   state.pagination.pages = 1;
  // },
  // [mutationTypes.FETCH_MYRESULT_SUCCESS]: (state, response) => {
  //   state.items = response.data;
  //   state.loading = false;
  //   state.pagination.totalElements = response.pagination.totalElements;
  //   state.pagination.pages = response.pagination.pages;
  // },
  // [mutationTypes.FETCH_MYRESULT_FAILURE]: (state) => {
  //   state.items = [];
  //   state.loading = false;
  //   state.pagination.totalElements = 0;
  //   state.pagination.pages = 1;
  // },
  [mutationTypes.FETCH_BIDSEARCH]: (state, page) => {
    state.items = [];
    state.loading = true;
    state.pagination.page = page;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_BIDSEARCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_BIDSEARCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.SHOW_AMOUNT]: (state) => {
    state.loading = true;
  },
  [mutationTypes.SHOW_AMOUNT_SUCCESS]: (state, data) => {
    state.items.filter((x) => x.num === data.num)[0].mem_view_dt = data.mem_view_dt;
    state.loading = false;
  },
  [mutationTypes.SHOW_AMOUNT_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.REGIST]: (state) => {
    state.loading = true;
  },
  [mutationTypes.REGIST_SUCCESS]: (state, data) => {
    state.items.filter((x) => x.num === data.num)[0].regist_yn = data.regist_yn;
    state.loading = false;
  },
  [mutationTypes.REGIST_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.TUCHAL]: (state) => {
    state.loading = true;
  },
  [mutationTypes.TUCHAL_SUCCESS]: (state, data) => {
    state.items.filter((x) => x.num === data.num)[0].tuchal_yn = data.tuchal_yn;
    state.loading = false;
  },
  [mutationTypes.TUCHAL_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.CREATE_MEMO]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_MEMO_SUCCESS]: (state, data) => {
    state.items.filter((x) => x.num === data.num)[0].mem_memo = data.mem_memo;
    state.loading = false;
  },
  [mutationTypes.CREATE_MEMO_FAILURE]: (state) => {
    state.loading = false;
  },

  // 개별선택 엑셀저장(selected)
  [mutationTypes.SELECTED]: (state, data) => {
    state.selected = data;
  },
};
