import Vue from 'vue';

export default {
  async fetchMarketingCon(params) {
    return Vue.axios.get(`/api/marketing/con/`, { params: params });
  },
  async fetchMarketingPur(params) {
    return Vue.axios.get(`/api/marketing/pur/`, { params: params });
  },
  async fetchMarketingSer(params) {
    return Vue.axios.get(`/api/marketing/ser/`, { params: params });
  },
  async fetchNewMarketingCon(params) {
    return Vue.axios.get(`/api/newmarketing/con/`, { params: params });
  },
  async fetchNewMarketingConUpdate(params) {
    return Vue.axios.post(`/api/newmarketing/con/update/`, { params: params });
  },
  async fetchMarketingDetail(params) {
    return Vue.axios.get(`/api/marketing/${params.bidtype}/${params.id}/`);
  },
  async fetchNewMarketingList(params) {
    return Vue.axios.get(`/api/newmarketing/list/`, { params: params });
  },
  async fetchNewMarketingDetail(params) {
    return Vue.axios.get(`/api/newmarketing/detail/${params.officeno}/`);
  },
  async getCounsel(params) {
    return Vue.axios.get(`/api/newmarketing/get-counsel?officeno=${params.officeno}`);
  },
  async getOrgTuchalCount(params) {
    return Vue.axios.get(`/api/powerkey/tuchalorgcount/`, {
      params: params,
    });
  },
};
