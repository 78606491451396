import VueCookies from 'vue-cookies';
import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

import axios from 'axios';

const state = {
  accessToken: VueCookies.get('token'),
  refreshToken: VueCookies.get('refresh_token'),
  refreshed: VueCookies.get('refreshed'),
  userid: VueCookies.get('userid'),
  saveid: VueCookies.get('saveid'),
  autologin: VueCookies.get('autologin'),
  loggedIn: !!VueCookies.get('token'),
  error: '',
  altoken: '',
  auth_items: null,
  login: '',
  loading: false,
  isSuperman: false,
};

const getters = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  refreshed: (state) => state.refreshed,
  userid: (state) => state.userid,
  saveid: (state) => state.saveid,
  autologin: (state) => state.autologin,
  loggedIn: (state) => state.loggedIn,
  error: (state) => state.error,
  altoken: (state) => state.altoken,
  auth_items: (state) => state.auth_items,
  login: (state) => state.login,
  loading: (state) => state.loading,
  isSuperman: (state) => state.isSuperman,
};

const mutations = {
  [mutationTypes.LOGIN]: (state) => {
    state.accessToken = null;
    state.refreshToken = null;
    state.refreshed = null;
    state.userid = null;
    state.error = '';
    state.loggedIn = false;
    state.auth_items = null;
    state.login = '';
    state.loading = true;
    state.isSuperman = false;
  },
  [mutationTypes.ALTOKEN_INIT]: () => {
    state.altoken = '';
  },
  [mutationTypes.LOGIN_SUCCESS]: (state, data) => {
    if (data.login == 'sms') {
      state.loggedIn = false;
    } else {
      // console.log('token data', data)
      const refresed = parseInt(new Date().valueOf() / 1000);
      VueCookies.set('userid', data.userid);
      VueCookies.set('usernum', data.usernum);
      VueCookies.set('userinfo', data.userinfo, '30d');
      VueCookies.set('name', data.name, '30d');
      VueCookies.set('token', data.token, '60min');
      // 2022-12-27 요청사항 - 관리자 로그이후 1일간 유효하도록 수정
      VueCookies.set('refresh_token', data.refresh_token, '15d'); // 15일로 변경 (토큰의 유효기간 설정은 API 의 JWT_REFRESH_TOKEN_EXPIRES 설정값으로 설정)
      VueCookies.set('refreshed', refresed, '60min');
      state.accessToken = data.token;
      state.refreshToken = data.refresh_token;
      state.refreshed = refresed;
      state.userid = data.userid;
      state.error = '';
      state.loggedIn = true;
      state.auth_items = data.auth_items;
      state.isSuperman = data.isSuperman === '1' ? true : false;
    }
    state.loading = false;
  },
  [mutationTypes.LOGIN_ERROR]: (state, message) => {
    state.error = message;
    state.loggedIn = false;
    state.auth_items = null;
    state.login = '';
    state.loading = false;
    state.isSuperman = false;
  },
  [mutationTypes.LOGOUT_SUCCESS]: (state) => {
    VueCookies.remove('userid');
    VueCookies.remove('token');
    VueCookies.remove('refresh_token');
    VueCookies.remove('name');
    VueCookies.remove('userinfo');
    VueCookies.remove('usernum');
    VueCookies.remove('refreshed');
    state.loggedIn = false;
    state.accessToken = null;
    state.refreshToken = null;
    state.refreshed = null;
    state.userid = null;
    state.auth_items = null;
    state.login = '';
    state.isSuperman = false;
  },
  [mutationTypes.ALLOW_AUTH]: (state) => {
    state.auth_items = null;
  },
  [mutationTypes.ALLOW_AUTH_SUCCESS]: (state, data) => {
    state.auth_items = data.auth_items;
  },
  [mutationTypes.ALLOW_AUTH_FAILURE]: (state) => {
    state.auth_items = null;
  },
  [mutationTypes.UPDATE_ACCESS_TOKEN]: (state, data) => {
    state.accessToken = data.token;
    state.refreshed = data.refreshed;
    state.loggedIn = data.token ? true : false;
  },
};

const actions = {
  [actionTypes.LOGIN]: async ({ commit }, { userid, password }) => {
    commit(mutationTypes.LOGIN);
    try {
      const { data } = await api.login(userid, password);
      data.userid = userid;
      commit(mutationTypes.LOGIN_SUCCESS, data);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    } catch (e) {
      const { data } = e.response;
      commit(mutationTypes.LOGIN_ERROR, data);
    }
  },
  [actionTypes.MANAGER_LOGIN]: async ({ commit }, { userid, password }) => {
    commit(mutationTypes.LOGIN);
    try {
      const { data } = await api.managerLogin(userid, password);
      data.userid = userid;
      data.usernum = data.mng_num;
      commit(mutationTypes.LOGIN_SUCCESS, data);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      return data;
    } catch (e) {
      if (e.response) {
        const { data } = e.response;
        commit(mutationTypes.LOGIN_ERROR, data);
      } else {
        commit(mutationTypes.LOGIN_ERROR, { msg: '로그인 할수없습니다.' });
      }
    }
  },
  [actionTypes.MANAGER_SMS_LOGIN]: async ({ commit }, { userid, senumber, logintype }) => {
    commit(mutationTypes.LOGIN);
    try {
      const { data } = await api.managerSmsLogin(userid, senumber, logintype);
      data.userid = userid;
      data.usernum = data.mng_num;
      commit(mutationTypes.LOGIN_SUCCESS, data);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      return data;
    } catch (e) {
      const { data } = e.response;
      commit(mutationTypes.LOGIN_ERROR, data);
    }
  },
  [actionTypes.AUTO_LOGIN]: async ({ commit }, { params }) => {
    commit(mutationTypes.LOGIN);
    try {
      const { data } = await api.autologin(params);
      commit(mutationTypes.LOGIN_SUCCESS, data);
    } catch (e) {
      const { data } = e.response;
      commit(mutationTypes.LOGIN_ERROR, data);
    }
  },
  [actionTypes.AL_TOKEN]: async ({ commit }, { mem_num }) => {
    try {
      const { data } = await api.altoken(mem_num);

      return data;
    } catch (e) {
      const { data } = e.response;
      commit(mutationTypes.LOGIN_ERROR, data);
    }
  },
  [actionTypes.LOGOUT]: async ({ commit }, { ltype }) => {
    const { data } = await api.logout(ltype);
    commit(mutationTypes.LOGOUT_SUCCESS);
  },
  [actionTypes.ALLOW_AUTH]: async ({ commit }) => {
    commit(mutationTypes.ALLOW_AUTH);
    try {
      const { data } = await api.allowAuth();
      commit(mutationTypes.ALLOW_AUTH_SUCCESS, data);
      return data;
    } catch (e) {
      commit(mutationTypes.ALLOW_AUTH_FAILURE);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
