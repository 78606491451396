import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    type: 'latest',
    bidtype: 'con',
    whereis: [],
    itemcode: '',
    from_date: '',
    to_date: '',
    location: '',
    local: '',
    officeno: '',
    officenm: '',
    filterednm: '',
    prenm: '',
    range: 'first',
    notinum: '',
    constnm: '',
    org: '',
    column: 'constnm',
    keyword: '',
    sess_team_id: '',
    sess_staff_num: '',
    page: 1,
    pageSize: 50,
  },
  chartItems: {},
  items: [],
  bizbasic: {},
  loading: false,
  total: 0,
  activeTab: 'list',
};

const getters = {
  form: (state) => state.form,
  chartItems: (state) => state.chartItems,
  items: (state) => state.items,
  bizbasic: (state) => state.bizbasic,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.chartItems = {};
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    console.log(response.data);
    // state.chartItems = response.chartItems;
    let chartItems = {
      basic: [],
      com_point0: [],
      com_point100: [],
      constdt: [],
      constnm: [],
      org_point0: [],
      org_point100: [],
      success1_point0: [],
      success1_point100: [],
    };

    for (let i = 0; i < response.data.length; i++) {
      const item = response.data[response.data.length - i - 1];
      chartItems['basic'].push(item.basic);
      chartItems['com_point0'].push(parseFloat(item.mytuchal.com.pctDecimal));
      chartItems['com_point100'].push(parseFloat(item.mytuchal.com.pctPer));
      chartItems['constdt'].push(item.constdt);
      chartItems['constnm'].push(item.constnm);
      chartItems['org_point0'].push(parseFloat(item.mytuchal.org.pctDecimal));
      chartItems['org_point100'].push(parseFloat(item.mytuchal.org.pctPer));
      chartItems['success1_point0'].push(parseFloat(item.mytuchal['1st'].pctDecimal));
      chartItems['success1_point100'].push(parseFloat(item.mytuchal['1st'].pctPer));
    }
    state.chartItems = chartItems;

    state.items = response.data;
    // state.bizbasic = response.bizbasic;
    state.loading = false;
    state.total = response.total_count;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.chartItems = {};
    state.items = [];
    state.bizbasic = {};
    state.loading = false;
    state.total = 0;
  },
  [mutationTypes.GET]: (state) => {
    state.bizbasic = {};
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.bizbasic = response.bizbasic;
  },
  [mutationTypes.GET_FAILURE]: (state) => {
    state.bizbasic = {};
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchAnalysisTuchal({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      console.log(e);
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.GET]: async ({ commit }) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getBizBasic({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.GET_FAILURE);
      } else {
        commit(mutationTypes.GET_SUCCESS, data);
      }
    } catch (e) {
      console.log(e);
      commit(mutationTypes.GET_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
