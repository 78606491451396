import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    writer: '',
    subject: '',
    content: '',
  },
  pagination: {
    page: 1,
    sort: 'create_time',
    desc: 'desc',
    itemsPerPage: 20,
  },
  items: [],
  loading: false,
  total: 0,
};

const getters = {
  form: (state) => state.form,
  pagination: (state) => state.pagination,
  items: (state) => state.items,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.form = {};
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.total = 0;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.UPDATE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.GET]: (state) => {
    state.form = {};
    state.loading = true;
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.form = response;
    state.loading = false;
  },
  [mutationTypes.GET_FAILURE]: () => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchAdminNotice({
        ...state.pagination,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createAdminNotice(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteAdminNotice(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getAdminNotice(payload);
      commit(mutationTypes.GET_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateAdminNotice(payload);
      commit(mutationTypes.UPDATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
