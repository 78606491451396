import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import axios from 'axios';

const state = {
  siteconfigset: window.siteconfig,
};

const getters = {
  // siteconfigset: (state) => state.siteconfigset,

  siteconfigset: (state) => {
    // state.siteconfigset = state.siteconfigset || {};
    if (!state.siteconfigset.hasOwnProperty('meta_naver')) {
      state.siteconfigset['meta_naver'] = '';
    }
    if (!state.siteconfigset.hasOwnProperty('meta_google')) {
      state.siteconfigset['meta_google'] = '';
    }
    return state.siteconfigset;
  },
};

const mutations = {
  SET_SITECONFIGSET: (state, data) => {
    state.siteconfigset = data == null ? state.siteconfigset : data;
    if (!state.siteconfigset.hasOwnProperty('meta_naver')) {
      state.siteconfigset['meta_naver'] = '';
    }
    if (!state.siteconfigset.hasOwnProperty('meta_google')) {
      state.siteconfigset['meta_google'] = '';
    }
  },
};

const actions = {
  [actionTypes.UPDATE]: async () => {
    try {
      await api.saveSiteConfig({
        ...state.siteconfigset,
      });
    } catch (e) {
      console.log(e);
    }
  },
  [actionTypes.SET]: async ({ commit }) => {
    try {
      const { data } = await axios.get('/api/siteconfigset/load?shopkey=' + window.siteconfig.name, {});
      commit('SET_SITECONFIGSET', data);
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
