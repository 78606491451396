import Vue from 'vue';
import qs from 'qs';

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

function getDates() {
  const today = new Date();
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(today.getFullYear() - 2);

  const from_date = getFormattedDate(twoYearsAgo);
  const to_date = getFormattedDate(today);

  return { from_date, to_date };
}

export default {
  async getItems(payload) {
    return Vue.axios.get('/api/mng/items/v2/', { params: payload });
  },
  async getProgressMembers(payload) {
    return Vue.axios.get('/api/mng/progress-members/', { params: payload });
  },
  async getNotinumCount(payload) {
    const path = '/api/esbid/search';
    const method = 'GET';
    const bidtype = ['con', 'ser', 'pur'];
    const { from_date, to_date } = getDates();

    let totalCount = 0;

    const requests = bidtype.map((type) => {
      const params = {
        word_column: 'notinum',
        word: payload.notinum,
        date_column: 'writedt',
        from_date: from_date,
        to_date: to_date,
        bid_suc: 'bid',
        useType: type,
        sort: '-writedt',
        path: path,
        method: method,
      };

      return Vue.axios.get('/api/analysis_gate/infose_api_proxy/', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
    });

    try {
      const responses = await Promise.all(requests);
      responses.forEach((response) => {
        totalCount += response.data.total_count;
      });
      return { data: { count: totalCount } };
    } catch (error) {
      console.error('Error fetching notinum count:', error);
      throw error;
    }
  },
};
