import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import axios from 'axios';

const state = {
  items: [],
};

const getters = {
  items: (state) => {
    return state.items;
  },
};

const mutations = {
  FETCH_ROWS: (state, data) => {
    state.items = data;
  },
};

const actions = {
  [actionTypes.UPDATE]: async ({ commit }, params) => {
    try {
      // await api.updateSiteManage(params);
      await axios.post(`/api/sitemanage/update`, params);
    } catch (e) {
      console.log(e);
    }
  },
  [actionTypes.GET]: async ({ commit }, { keyword, state }) => {
    try {
      const { data } = await axios.get('/api/sitemanage/load', {
        params: {
          keyword,
          state,
        },
      });
      // console.log(data);
      commit('FETCH_ROWS', data);
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
