export const GET_BIDBOXES = 'BIDBOX_GET_BIDBOXES';
export const GET_BIDBOX = 'BIDBOX_GET_BIDBOX';
export const UPDATE_ANALDATA = 'BIDBOX_UPDATE_ANALDATA';
export const UPDATE_STATE = 'BIDBOX_UPDATE_STATE';
export const UPDATE_TEAMDOCINFO = 'BIDBOX_UPDATE_TEAMDOCINFO';
export const UPDATE_ANALDATA_MNGMEMO = 'BIDBOX_UPDATE_ANALDATA_MNGMEMO';
export const COPY_FROM_PREV = 'BIDBOX_COPY_FROM_PREV';
export const COPY_TO_DELAYED_FROM_PREV = 'BIDBOX_COPY_TO_DELAYED_FROM_PREV';
export const GET_MEMBER_POPOVER = 'BIDBOX_GET_MEMBER_POPOVER';
export const GET_SAJUNG_HISTORY = 'BIDBOX_GET_SAJUNG_HISTORY';
export const UPDATE_ANALDATA_BIDID = 'BIDBOX_UPDATE_ANALDATA_BIDID';
