import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { refreshToken } from '../auth.js';
import { RoutesWWW } from './routes.js';
import { store, errorStore } from '../store/index';
import axios from 'axios';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: RoutesWWW,
});

router.beforeEach(async (to, from, next) => {
  errorStore.dispatch('clearError'); // 에러를 초기화한다. (에러를 지우지 않으면, 페이지 이동시 에러가 남아있음.)

  // 404 페이지나 에러 페이지로 가는 경우에는 로그인 체크를 하지 않는다.
  const resolvedRoute = router.resolve(to.fullPath);
  // console.log('resolvedRoute', resolvedRoute.resolved)
  if (resolvedRoute.resolved.name === 'NotFound' || resolvedRoute.resolved.path.startsWith('/error/')) {
    return next();
  }

  //사이트 상태가 N인경우 403페이지로 이동(sitemanager에서 설정한 상태가 N인경우  403페이지로 이동)
  if (process.env.NODE_ENV !== 'development') {
    const response = await axios.get('/api/sitemanage/get_state', {
      params: { shopkey: window.siteconfig.name },
    });
    const state = response.data.state;
    if (state === 'N') {
      return next('/error/403');
    }
  }

  if (VueCookies.get('token') === null && VueCookies.get('refresh_token') !== null) {
    await refreshToken();
  }

  // if (to.matched.some((record) => record.meta.unauthorized) || VueCookies.get('token')) {
  if (to.matched.some((record) => record.meta.unauthorized) || store.getters.accessToken) {
    return next();
  }

  return next('/client/login');
});

export default router;
