import Vue from 'vue';
import VueCookies from 'vue-cookies';

export default {
  async login(userid, password) {
    var ip_addr = await this.getIp();
    return Vue.axios.post('/api/auth/login', {
      userid,
      password,
      ip_addr,
    });
  },
  async managerLogin(userid, password) {
    var ip_addr = await this.getIp();
    return Vue.axios.post(`/api/auth/managerlogin?shopkey=${window.siteconfig.name}`, {
      userid,
      password,
      ip_addr,
    });
  },
  async managerSmsLogin(userid, senumber, logintype) {
    var ip_addr = await this.getIp();
    return Vue.axios.post('/api/auth/managerlogin', {
      userid,
      senumber,
      logintype,
      ip_addr,
    });
  },
  async autologin(token) {
    return Vue.axios.post('/api/auth/autologin', {
      token,
    });
  },
  async altoken(mem_num) {
    const access_token = VueCookies.get('token');
    Vue.axios.defaults.headers.common = {
      Authorization: `Bearer ${access_token}`,
      Shopkey: window.siteconfig.name,
    };

    return Vue.axios.post('/api/auth/altoken', {
      mem_num,
    });
  },
  async allowAuth() {
    return Vue.axios.get(`/api/auth/allowauth`);
  },
  async myip(url) {
    try {
      const response = await Vue.axios.get(url, { timeout: 6000 });
      if (response.status == 200) {
        return response.data;
      }
    } catch (error) {
      console.log(`ip 확인 오류(${url}):`, error);
    }
    return null;
  },
  async getIp() {
    // ip 확인을 위한 api 호출 (ip 확인이 안될경우를 대비하여 2개 이상의 api를 호출함) 2023-05-30 수정
    let myip = null;
    // myip = await this.myip('https://corsproxy.io/?https://checkip.amazonaws.com/');
    // if (myip) {
    //   //console.log(`ip check 1 ${myip.trim()}`);
    //   return myip.trim();
    // }
    // myip = await this.myip('https://corsproxy.org/?https://checkip.amazonaws.com/');
    // if (myip) {
    //   //console.log(`ip check 2 ${myip.trim()}`);
    //   return myip.trim();
    // }
    myip = await this.myip('https://api.34-111-116-224.nip.io/api/auth/myip/');
    if (myip && myip.trim().length > 0) {
      //console.log(`ip check 3 ${myip.trim()}`);
      return myip.trim();
    }
    myip = await this.myip('https://httpbin.org/ip');
    if (myip && myip.origin) {
      //console.log(`ip check 4 ${myip.origin}`);
      return myip.origin;
    }
    console.log('** ip check 실패!');
    return 'null';
  },
  async logout(ltype) {
    ltype = ltype ? ltype : 'admin';
    var ip_addr = await this.getIp();
    var usernum = VueCookies.get('usernum');
    return Vue.axios.post('/api/auth/logout', {
      ltype,
      usernum,
      ip_addr,
    });
  },
};
