import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    biddate_term: '0',
    bidtype: '',
    itemcode1: '',
    itemcode2: '',
    itemcode3: '',
    location: '',
    addr: '',
    officeno: '',
    officename: '',
    tel: '',
    prename: '',
    fax: '',
    folder_num: 0,
    org: '',
    charge_bidq: '',
    charge_info: '',
    counsel_kind: '',
    counsel_date: '',
    mng_num: '',
    sort: 'update_time',
    datetype: 'update_time',
    desc: 'desc',
  },
  items: [],
  counsels: [],
  loading: false,
  pagination: {
    page: 1,
    size: 40,
    totalElements: 0,
    pages: 1,
  },
  check_com: [],
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  check_com: (state) => state.check_com,
  counsels: (state) => state.counsels,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.counsels = [];
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, { data, total, counsels }) => {
    state.items = data;
    state.loading = false;
    state.pagination.totalElements = total;
    state.counsels = counsels;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.counsels = [];
    state.items = [];
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchNewMarketingList({
        ...form,
      });
      let counsels = [];
      data.data.map(async (item) => {
        await api.getCounsel({ officeno: item.officeno }).then((res) => {
          counsels.push({
            officeno: item.officeno,
            content: res.data.content,
            writedate: res.data.writedate,
          });
        });
      });
      commit(mutationTypes.FETCH_SUCCESS, {
        data: data.data,
        total: data.total,
        counsels: counsels,
      });
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  setViewCom: ({ state }, session_com) => {
    state.check_com = session_com;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
