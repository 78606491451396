import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  items: [],
  loading: false,
  allupdate_loading: false,
  save_loading: false,
  form: {
    bidtype: 'con',
    itemcode: [],
    pur_codes: [],
    g2b_code: [],
    constnm_filter: '',
    location: '0',
    use_local: 'N',
    slocals: '0000',
    apt_use: 'N',
    pq_use: 'N',
    sidam_use: 'N',
    lowprice_use: 'N',
    step2sep_use: 'N',
    null_notinum: 'N',
    filter_woman: 'N',
    jikchal_use: 'N',
    otherlocal_use: 'N',
    jimung_use: 'N',
    except_orgs: [],
    except_organ: null,
    ip_amt_round: 'empty',
    basic_min: null,
    basic_max: null,
    presum_min: null,
    presum_max: null,
    basic_min_2: null,
    basic_max_2: null,
    presum_min_2: null,
    presum_max_2: null,
    bidtype_only: 'N',
  },
  logs: [],
};

const getters = {
  items: (state) => state.items,
  loading: (state) => state.loading,
  allupdate_loading: (state) => state.allupdate_loading,
  save_loading: (state) => state.save_loading,
  form: (state) => state.form,
  logs: (state) => state.logs,
};

const mutations = {
  initForm: (state) => {
    state.form = {
      bidtype: 'con',
      itemcode: [],
      pur_codes: [],
      g2b_code: [],
      location: '0',
      use_local: 'N',
      slocals: '0000',
      apt_use: 'N',
      pq_use: 'N',
      sidam_use: 'N',
      lowprice_use: 'N',
      step2sep_use: 'N',
      null_notinum: 'N',
      filter_woman: 'N',
      jikchal_use: 'N',
      otherlocal_use: 'N',
      jimung_use: 'N',
      except_orgs: [],
      except_organ: null,
      ip_amt_round: 'empty',
      basic_min: null,
      basic_max: null,
      presum_min: null,
      presum_max: null,
      basic_min_2: null,
      basic_max_2: null,
      presum_min_2: null,
      presum_max_2: null,
    };
  },
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.result;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.save_loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: (state) => {
    state.save_loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: (state) => {
    state.save_loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.allupdate_loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state) => {
    state.allupdate_loading = false;
  },
  [mutationTypes.UPDATE_FAILURE]: (state) => {
    state.allupdate_loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.FETCH + '_log']: (state) => {
    state.logs = [];
    // state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS + '_log']: (state, response) => {
    state.logs = response;
    // state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE + '_log']: (state) => {
    state.logs = [];
    // state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }, payload) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchAnalconfig(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createAnalconfig({
        ...state.form,
      });
      commit(mutationTypes.CREATE_SUCCESS);
      commit('initForm');
      return data;
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
      commit('initForm');
    }
  },
  [actionTypes.UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateAllAnalconfig(payload);
      commit(mutationTypes.UPDATE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteAnalconfig(payload);
      commit(mutationTypes.DELETE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
  ['analconfigLog']: async ({ commit }, payload) => {
    commit(mutationTypes.FETCH + '_log');
    try {
      const { data } = await api.analconfigLog(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE + '_log');
      } else {
        commit(mutationTypes.FETCH_SUCCESS + '_log', data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE + '_log');
    }
  },
  initForm: ({ commit }) => {
    commit('initForm');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
