import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  office: {
    id: 0,
    officename: '',
    officeno: '',
    prenamej: '',
    phone: '',
    fax: '',
    addr: '',
  },
  upjong: [],
  biddata: {
    rank1_count: 0,
    last_bid_date: '',
  },
  loading: false,
  orgTuchalCount: {
    d2b: 0,
    etc0: 0,
    etc1: 0,
    g2b: 0,
    kepco: 0,
    lh: 0,
  },
};

const getters = {
  office: (state) => state.office,
  upjong: (state) => state.upjong,
  biddata: (state) => state.biddata,
  loading: (state) => state.loading,
  orgTuchalCount: (state) => state.orgTuchalCount,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.office = {};
    state.upjong = [];
    state.biddata = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.office = response.office;
    state.upjong = response.upjong;
    state.biddata = response.biddata;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.office = {};
    state.upjong = [];
    state.biddata = [];
    state.loading = false;
  },
  orgTuchalCountInit: (state) => {
    state.orgTuchalCount = {};
  },
  setOrgTuchalCount: (state, data) => {
    state.orgTuchalCount = data;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }, payload) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchNewMarketingDetail(payload);
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  GET_ORG_TUCHAL_COUNT: async ({ commit }, officeno) => {
    commit('orgTuchalCountInit');
    try {
      const { data } = await api.getOrgTuchalCount(officeno);
      commit('setOrgTuchalCount', data);
    } catch (e) {
      commit('orgTuchalCountInit');
      console.log('GET_ORG_TUCHAL_COUNT error : ', e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
