import Vue from 'vue';

export default {
  async fetchHome() {
    return Vue.axios.get(`/api/home/data`);
  },
  async fetchNewboard() {
    return Vue.axios.get(`/api/home/newboard`);
  },
};
