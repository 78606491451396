const state = {
  code: null,
  message: null,
};

const getters = {
  error: (state) => state.code !== null,
  code: (state) => state.code,
  message: (state) => state.message,
};

const mutations = {
  setError: (state, { code, message }) => {
    state.code = code;
    state.message = message;
  },
};

const actions = {
  setError: ({ commit }, payload) => {
    commit('setError', {
      code: String(payload.code) || 'unknown',
      message: payload.message || 'An error occurred',
    });
  },
  clearError: ({ commit }) => {
    commit('setError', {
      code: null,
      message: null,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
