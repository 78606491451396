// import VueCookies from 'vue-cookies';
import { refreshAccessTokenAuto } from '../auth.js';
import { store } from '../store/index';

export default async function(config) {
  // console.log('리프레시 토큰 프로세스 시작...', store)

  // 리프레시 토큰이 필요없는 URL (추후 ip 체크 api 추가 필요)
  const passUrls = [
    '/api/auth/login',
    '/api/auth/managerlogin',
    '/api/auth/logout',
    '/api/auth/refresh',
    '/api/auth/allowauth',
    'api.34-111-116-224.nip.io/api/auth/myip/',
    'httpbin.org/ip',
  ];

  for (const passUrl of passUrls) {
    if (config.url.includes(passUrl)) {
      return config;
    }
  }

  // 현재 액세스 토큰과 새로운 액세스 토큰이 다르면 새로운 액세스 토큰으로 교체
  const currentAccessToken = store.getters.accessToken;
  await refreshAccessTokenAuto(60 * 20); // 20분마다 액세스 토큰 갱신 (api의 access_token 만료시간 30분으로 설정되어 있음, 20분 이상 경과하면 갱신)
  const newAccessToken = store.getters.accessToken;

  if (currentAccessToken !== newAccessToken) {
    // console.log('액세스 토큰 갱신됨', config.url);
    config.headers.Authorization = `Bearer ${newAccessToken}`;
  }

  // 최종 설정된 config 반환
  return config;
}
