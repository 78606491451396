import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import axios from 'axios';

const state = {
  items: [],
  shopkeys: [],
  total: 0,
  form: {
    keyword: '',
    state: 'Y',
    page: 1, // 현재 페이지
    pageSize: 10, // 페이지 당 표시될 아이템 수
  },
};

const getters = {
  items: (state) => {
    return state.items;
  },
  shopkeys_dict: (state) => {
    return state.shopkeys;
  },
  form: (state) => {
    return state.form;
  },
  total: (state) => {
    return state.total;
  },
};

const mutations = {
  FETCH_INIT: (state) => {
    state.items = [];
  },

  FETCH_ROWS: (state, data) => {
    state.items = data.items;
    state.total = data.total;
  },
  FETCH_SHOP_ROWS: (state, data) => {
    state.shopkeys = data;
  },
};

const actions = {
  [actionTypes.UPDATE]: async ({ commit }, params) => {
    try {
      const { data } = await axios.post(`/api/popup/upload`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  get_shops: async ({ commit }) => {
    try {
      const { data } = await axios.get('/api/popup/shopkeys');
      commit('FETCH_SHOP_ROWS', data);
    } catch (e) {
      console.log(e);
    }
  },
  [actionTypes.GET]: async ({ commit }) => {
    try {
      commit('FETCH_INIT');
      const { data } = await axios.get('/api/popup/load', {
        params: state.form,
      });
      commit('FETCH_ROWS', data);
    } catch (e) {
      console.log(e);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, id) => {
    try {
      const { data } = await axios.delete(`/api/popup/delete`, {
        params: {
          id: id,
        },
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
