import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    title: '',
    content: '',
    created_at: '',
  },
  pagination: {
    page: 1,
    sort: 'created_at',
    desc: 'desc',
    itemsPerPage: 10,
  },
  board_timeline: [],
  loading: false,
  total: 0,
};

const getters = {
  form: (state) => state.form,
  pagination: (state) => state.pagination,
  loading: (state) => state.loading,
  total: (state) => state.total,
  board_timeline: (state) => state.board_timeline,
  // items: (state) => state.items,
};
const mutations = {
  [mutationTypes.NEW_NOTICE_FETCH]: (state) => {
    state.form = {};
    state.board_timeline = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.NEW_NOTICE_SUCCESS]: (state, { response, page }) => {
    state.board_timeline = response.board_timeline;
    state.total = response.total;
    state.pagination.page = page;
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_FAILURE]: (state) => {
    state.loading = false;
    state.total = 0;
    // state.items = [];
  },
  [mutationTypes.NEW_NOTICE_CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.NEW_NOTICE_CREATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_CREATE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.NEW_NOTICE_UPDATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_UPDATE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.NEW_NOTICE_DELETE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_DELETE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.NEW_NOTICE_GET]: (state) => {
    state.form = {};
    state.loading = true;
    state.board_timeline = [];
  },
  [mutationTypes.NEW_NOTICE_GET_SUCCESS]: (state, response) => {
    state.form = response;
    state.loading = false;
    state.board_timeline = response.board_timeline;
  },
  [mutationTypes.NEW_NOTICE_GET_FAILURE]: () => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.NEW_NOTICE_FETCH]: async ({ commit, state }, payload = {}) => {
    commit(mutationTypes.NEW_NOTICE_FETCH);
    try {
      const page = payload.page !== undefined ? payload.page : state.pagination.page;
      const pagination = { ...state.pagination, page };
      const { data } = await api.fetchNewNotice(pagination);
      commit(mutationTypes.NEW_NOTICE_SUCCESS, { response: data, page });
    } catch (e) {
      commit(mutationTypes.NEW_NOTICE_FAILURE);
    }
  },

  [actionTypes.NEW_NOTICE_CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.NEW_NOTICE_CREATE);
    try {
      const { data } = await api.createNewNotice(payload);
      commit(mutationTypes.NEW_NOTICE_CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.NEW_NOTICE_CREATE_FAILURE);
    }
  },
  [actionTypes.NEW_NOTICE_DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.NEW_NOTICE_DELETE);
    try {
      const { data } = await api.deleteNewNotice(payload);
      commit(mutationTypes.NEW_NOTICE_DELETE_SUCCESS, data);
      dispatch(actionTypes.NEW_NOTICE_FETCH);
    } catch (e) {
      commit(mutationTypes.NEW_NOTICE_DELETE_FAILURE);
    }
  },

  [actionTypes.NEW_NOTICE_GET]: async ({ commit }, payload) => {
    // console.log('--------------------------', api, api.getNewNotice);
    commit(mutationTypes.NEW_NOTICE_GET);
    try {
      const { data } = await api.getNewNotice(payload);
      commit(mutationTypes.NEW_NOTICE_GET_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.NEW_NOTICE_GET_FAILURE);
    }
  },

  [actionTypes.NEW_NOTICE_UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.NEW_NOTICE_UPDATE);
    try {
      const { data } = await api.updateNewNotice(payload);
      commit(mutationTypes.NEW_NOTICE_UPDATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.NEW_NOTICE_UPDATE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
